import saona_1 from '../../assets/images/Saona.jpg'

const saona = {
    _id: "saona-tour",
    title: "ISLA SAONA",
    subtitle: "una de las playas mas bellas de la isla",
    hero:'saona-hero',
    stops: [
        {
            title: "Hotel",
            description: "Pasaremos a recogerlos en su hotel y nuestro guía turístico los acompañará e informara de las actividades del día. Camino a la región de Bayahibe, inicia vuestra aventura.",
        },
        {
            title: "Puerto de Bayahibe",
            description: "Desde el puerto de Bayahibe y salvavidas en mano, tomaremos una lancha rápida hacia una de las islas más bonitas del Caribe, ¡Saona!. No sin antes hacer una pequeña parada...",
        },
        {
            title: "Palmilla",
            description: "Bienvenido a la piscina natural de Palmilla, con una increible vista, fauna marina y aguas cristalinas podrá disfrutar de un relajante paisaje. Un perfecto abre bocas antes de llegar a nuestro destino.",
        },
        {
            title: "Canal de Catuano",
            description: "Al finalizar nuestra primera parada, retomamos nuestro viaje a la isla Saona, pasando frente al canal Catuano.",
        },
        {
            title: "Isla Saona",
            description: "Finalmente llegamos a la mejor area de la playa en toda la isla, le ofreceremos un menú increible y el disfrute de nuestros servicios.",
        },
        {
            title: "Regreso a Bayahibe",
            description: "Es hora de volver, pero aún no ha terminado nuestro viaje. Disfrutaremos de barra libre y un espacio de recreación en el catamarán de regreso.",
        },
        {
            title: "Despedida",
            description: "Al llegar a Bayahibe, nuestro autobús estara esperandolo para regresarlo a la comodidad de su hotel junto con esta maravillosa experiencia.",
        }
    ],
    info: {
        duration: "8 horas",
        reservation: "Reservas al tlf:"
    },
    includes: [
        "Guía turístico",
        "Bar",
        "Almuerzo",
        "Seguridad",
        "Transporte",
        "Recreación"
    ],
    cost: 40,
}

export default saona