import React, {useEffect, useState} from "react";
import {CloudinaryContext, Image} from "cloudinary-react";
import Slider from "react-slick";
import axios from "axios";

export default ({leng}) => {
    const cloud  = process.env.REACT_APP_CLOUDINARY_CLOUD
    const [carouselImages,setImages] = useState([])
    const getImages = async () => {
        const response = await axios.get(`https://res.cloudinary.com/${cloud}/image/list/carousel.json`)
        setImages(response.data.resources)
    }

    useEffect(()=>{
        getImages()
    },[])

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:2500,
        responsive:[
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows:true
                }
            }
        ]
    };

    const HeroMobile = ({imageId}) => {
        return <CloudinaryContext cloudName={cloud} className={'w-[300px] h-[65vh] mx-auto mobile:w-full px-4 flex items-center mobile_stroke justify-center relative'}>
            <Image publicId={imageId} width="1300" className={'object-cover object-top top-0 w-full h-full'}/>
        </CloudinaryContext>
    }

    const shuffleImages = carouselImages.map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

    return <>
        <div
            className={
                'flex flex-col hero pt-[60px] mobile:pt-20 flex-1 z-10 w-full items-center relative px-16 bg-buggy bg-center bg-no-repeat bg-cover'
            }
        >
            <h1 className={'title'}>{leng ==='es' ? 'La Aventura te espera' :'Adventure awaits'}</h1>
            {shuffleImages.length
                ? <Slider {...settings} className={'w-full py-16 z-10'}>
                    {shuffleImages.map((image)=><HeroMobile imageId={image.public_id}/>)}
                </Slider>
                : null
            }
        </div>
    </>
};
