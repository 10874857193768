import './App.css'
import Home from './views/Home'
import {Link, Navigate, Route, Routes} from 'react-router-dom'
import Service from './views/Service'
import Contact from './views/Contact'
import Navbar from "./components/navbar/Navbar";
import whatsappIcon from './assets/images/Whatsapp.png'
import {useState} from "react";
import Photos from "./views/Photos";

function App() {
    const [leng,setLeng] = useState('es')
    return (
        <div className="bg-primary select-none w-full flex justify-center min-h-screen">
            <div
                className={
                    'appWidth flex flex-col flex items-center justify-center h-full min-h-screen relative'
                }
            >
                <Routes>
                    <Route path={'/'} element={<Navbar leng={leng} setLeng={setLeng}/>} >
                        <Route index element={<Home leng={leng}/>} />
                        <Route
                            path="/services/:service"
                            element={<Service leng={leng}/>}
                        />
                        <Route path="/contact" element={<Contact leng={leng}/>} />
                        <Route path="/photos" element={<Photos leng={leng}/>} />
                        <Route
                            path={'*'}
                            element={<Navigate replace to={'/'} />}
                        />
                    </Route>
                </Routes>
                <div className={'fixed bottom-10 right-10 rounded-full overflow-hidden w-[25px] h-[25px] sm:w-10 sm:h-10 z-20 opacity-80'}>
                    <a href={'https://api.whatsapp.com/send?phone=18092646507'} target={"_blank"}>
                        <img src={whatsappIcon}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default App