import { useParams } from 'react-router-dom'
import saona from '../utils/services/saona-island.js'
import buggy from '../utils/services/buggy-tours.js'
import horse from '../utils/services/horse-ride.js'
import saonaEn from '../utils/services/saona-island-en.js'
import buggyEn from '../utils/services/buggy-tours-en.js'
import horseEn from '../utils/services/horse-ride-en.js'
import taiguey from "../utils/services/taiguey-emotion-show";
import taigueyEn from "../utils/services/taiguey-emotion-show-en";

import FeatureCard from "../components/service/FeatureCard";
import Hero from "../components/hero/Hero";
import Footer from "../components/footer/Footer";
import {CloudinaryContext, Image, Transformation} from "cloudinary-react";
import Carousel from "../components/carousel/CarouselService";
import {useEffect, useState} from "react";
import axios from "axios";

export default ({leng}) => {
    const cloud  = process.env.REACT_APP_CLOUDINARY_CLOUD
    const { service } = useParams()
    const selectInfo = () => {
        if (service === 'saona-tour') return leng === 'es' ? saona : saonaEn
        if (service === 'buggy-tour') return leng === 'es' ? buggy : buggyEn
        if (service === 'riding-tour') return leng === 'es' ? horse : horseEn
        if (service === 'taiguey-show') return leng === 'es' ? taiguey : taigueyEn
    }
    const [carouselImages,setImages] = useState([])
    const getImages = async () => {
        const response = await axios.get(`https://res.cloudinary.com/${cloud}/image/list/${service}.json`)
        setImages(response.data.resources)
    }

    useEffect(()=>{
        getImages()
    },[])

    useEffect(()=>{
        getImages()
    },[service])

    const { title, subtitle, stops, hero } = selectInfo()
    return (
        <>
            <Hero imageId={`chamo-tour/${hero}`} title={title} subtitle={subtitle}/>
            <h1 className={'w-full text-center text-[34px] font-GelionBold text-white my-8'}>
                {leng === 'es' ? '¿Que Ofrecemos?' : 'What we offer?'}
            </h1>
            <div className={'flex w-full px-16 flex-col-reverse md:flex-row'}>
                <div className={'flex flex-wrap w-full md:w-[300px]'}>
                    <FeatureCard stops={stops}/>
                </div>
                <div className={'hidden md:flex w-full mx-8 pl-8 h-auto relative'}>
                    <div className={'flex flex-1 flex-col gap-4 h-full w-full absolute origin-top-right overflow-y-scroll scrollbar'}>
                        {carouselImages.map((image)=>{
                            return <CloudinaryContext cloudName={cloud} className={'w-full'}>
                                <Transformation aspectRatio="16:9" crop="crop" />
                                <Image publicId={image.public_id} className={'object-top w-full'}/>
                            </CloudinaryContext>
                        })}
                    </div>
                </div>
                <div className={'inline md:hidden'}>
                    {carouselImages.length
                        ? <Carousel carouselImages={carouselImages} />
                        : null
                    }
                </div>
            </div>
            <Footer leng={leng}/>
        </>
    )
}
