const taiguey = {
    _id: "taiguey-show",
    title: "TAIGUEY EMOTION SHOW",
    subtitle: "¡La increible fiesta temática caribeña alusiva a Los Taínos!",
    hero:'taiguey-hero',
    stops: [
        {
            title: "Hotel",
            description: "Pasaremos a recogerlos en su hotel y nos dirigiremos a la hermosa playa Bavaro donde se llevaran a cabo todas nuestras actividades.",
        },
        {
            title: "Museo experiencial",
            description: "Visita nuestro museo Taino donde podrás conocer sobre la cultura e historia de nuestros ancestro, y participaras en actividades artesanales haciendo piezas de barro y/o a moldear figuras de rituales indígenas",
        },
        {
            title: "Snorkling",
            description: "Nos adentraremos al Mar Caribe donde disfrutraras deslumbrantes arrecifes coralinos y conocer el asombroso mundo submarino en una de las experiencia de snorkling.",
        },
        {
            title: "Show Taino",
            description: "Disfrute navegando por la asombrosa costa cristalina de Punta Cana con nuestros show taíno y bailarines profesionales los cuales se asegurarán de enseñarte unos cuantos pasos tradicionales de ritmos caribeños",
        },
        {
            title: "Piscina Natural",
            description: "Seguiremos navegando hasta llegar a una magnifica piscina natural de arena blanca, donde podras relajarte y descansar en aguas cristalinas y contemplar la belleza caribeña.",
        },
        {
            title: "Regreso a Bavaro",
            description: "Con las energias renovadas continuamos disfrutando de los ritmos caribeños actuales, y realizaremos una competencia de baile, donde habra un premio al mejor ritmo.",
        },
        {
            title: "Despedida",
            description: "Al llegar al punto de partida, nuestro autobús estara esperandolo para regresarlo a la comodidad de su hotel junto con esta maravillosa experiencia.",
        }
    ]
}

export default taiguey