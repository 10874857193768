import React from 'react'

// React Router v6
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// Global style
import './index.css'

// Routes
import App from './App.js'
import ScrollToTop from "./hooks/ScrollToTop";

// Render
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)