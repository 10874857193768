const buggy = {
    _id: "buggy-tour",
    title: "ADVENTURE BUGGIES",
    subtitle: "vive el lado extremo de la isla",
    hero:'buggy-hero',
    stops: [
        {
            title: "Hotel",
            description: "Desde las 7:30am lo recogemos en su hotel con destino a nuestro rancho, disfrutara de un relajante paisaje mientras sueña con todo lo que hará en el día.",
        },
        {
            title: "Rancho Macao",
            description: "Al llegar a nuestro rancho, nuestro guia turistico le explicara las actividades, lo colocará tras el volante, encenderá motor e irá rumbo a playa Macao",
        },
        {
            title: "Playa Macao",
            description: "Preferida por todos, la playa Macao destaca por ser la mas limpia y con la arena mas blancas de la región. Luego de un chapuzon, conduciremos a la siguiente parada.",
        },
        {
            title: "Casa Cultural",
            description: "Llegaremos a la casa tipica de Republica Dominicana, conoceran nuestras plantas de cafe, cacao, tabaco y tendran la oportunidad de ver y probar el trabajo artesanal.",
        },
        {
            title: "Cueva Taina",
            description: "Finalmente la mejor parada de esta aventura. Disfrutará de aguas frescas y cristalinas para rejuvenecer el alma en este maravilloso cenote.",
        },
        {
            title: "Rancho Macao",
            description: "De vuelta al rancho, tienen la opcion de revisar los recuerdos tomados por nuestro equipo fotografico, tomar nuestra bebida nacional la MamaJuana! y darse una ducha.",
        },
        {
            title: "Regreso",
            description: "Lo regresaremos a la comodidad de su hotel entre las 6pm de la tarde. Luego de esta maravillosa experiencia se merece descansar.",
        }
    ],
    info: {
        duration: "8 horas",
        reservation: "Reservas al tlf:"
    },
    includes: [
        "Buggy",
        "Bar",
        "Aventura",
        "Seguridad",
        "Transporte",
        "Recreación"
    ],
    cost: 40,
}

export default buggy
