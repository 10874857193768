import beachIcon from '../../assets/images/beach-svgrepo-com.svg'
import Carousel from "../carousel/CarouselHome";

export default ({leng}) => {
    return (
        <div
            className={
                'flex flex-col flex-1 z-10 w-full items-center relative px-16'
            }
        >
            <span className={'absolute bg-secundary h-full -z-10 bg-clip-border m-auto w-screen'}/>
            <h1 className={'title mt-16'}>{leng ==='es' ? 'Nuestros clientes' :'Our clients'}</h1>
            <h2 className={'subtitle text-center'}>
                {leng ==='es' ? 'Se fueron con la mejor experiencia' :'Had the best experience'}
            </h2>
            <Carousel />
        </div>
    )
}
