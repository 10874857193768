import BurgerMenu from '../../assets/svg/BurgerMenu.jsx'
import CloseIcon from '../../assets/svg/CloseIcon.jsx'
import esFlag from "../../assets/images/es-flag.png";
import usFlag from "../../assets/images/us-flag.png";

export default ({ show, setShow, leng, setLeng }) => {
    return (
        <div
            aria-label="toggler"
            className="flex justify-end items-center mobile:hidden"
        >
            <div className={'flex relative w-full h-[30px] px-8'}>
                {leng === 'es'
                    ? <img src={esFlag} alt={'Español'} className={'w-[30px] h-[30px] absolute -z-10 left-8'}/>
                    : <img src={usFlag} alt={'English'} className={'w-[30px] h-[30px] absolute -z-10 left-8'}/>
                }
                <select
                    defaultValue={'es'}
                    onChange={(e)=>setLeng(e.target.value)}
                    className={'m-0 p-0 pl-[10px] bg-transparent text-transparent h-[30px] w-[60px] text-[16px] outline-none border-0 focus:outline-0 focus:boder-0 text-darkGray'}>
                    <option value={'es'} selected={leng==='es'} className={'bg-primary text-[16px] text-white'}>Esp</option>
                    <option value={'en'} selected={leng==='en'} className={'bg-primary text-[16px] text-white'}>Eng</option>
                </select>
            </div>
            <button
                id="open"
                onClick={() => setShow(!show)}
                aria-label="open"
                className={`${
                    show ? 'hidden' : ''
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800`}
            >
                <BurgerMenu/>
            </button>
            <button
                id="close"
                onClick={() => setShow(!show)}
                aria-label="close"
                className={`${
                    show ? '' : 'hidden'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800`}
            >
                <CloseIcon />
            </button>
        </div>
    )
}
