export default ({ stops }) => {
    return (
        <div className={'w-full flex flex-wrap justify-center md:flex-col gap-4'}>
            {stops.map((stop) => {
                return (
                    <>
                        <div className={'h-fit flex flex-col w-[250px]'}>
                            <h1 className={'font-GelionBold text-secundary text-[16px] mobile:text-[1.5rem]'}>{stop.title}</h1>
                            <span className={'text-textPrimary text-[14px] mobile:text-[1.25rem]'}>
                                {stop.description}
                            </span>
                        </div>
                    </>
                )
            })}
        </div>
    )
}
