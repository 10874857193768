const horseEn = {
    _id: "riding-tour",
    title: "HORSE RIDING",
    subtitle: "enjoy a dream space",
    hero:'riding-hero',
    stops: [
        {
            title: "Hotel",
            description: "We will pick you up at your hotel in a comfortable mini-bus, and we will go to our ranch.",
        },
        {
            title: "Ranch",
            description: "They will arrive at our ranch where they will be given an informative talk about the route to be taken. Where you will visit a typical Dominican house and you will be able to enjoy our beautiful Macao beach",
        },
        {
            title: "Return",
            description: "After completing your stay, we will return you to the comfort of your hotel. Totally renovated!",
        }
    ]
}

export default horseEn