import Hero from '../components/hero/HomeHero'
import Footer from '../components/footer/Footer'
import Features from '../components/home/Features'
import Clients from '../components/home/Clients'

export default ({leng}) => {
    return (
        <>
            <Hero />
            <Features leng={leng}/>
            <Clients leng={leng}/>
            <Footer leng={leng}/>
        </>
    )
}
