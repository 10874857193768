const horse = {
    _id: "riding-tour",
    title: "HORSE RIDING",
    subtitle: "maravillate con un espacio de ensueño",
    hero:'riding-hero',
    stops: [
        {
            title: "Hotel",
            description: "Pasaremos a recogerlos en su hotel en un comodo mini-bus, y nos dirigiremos a nuestro rancho.",
        },
        {
            title: "Rancho",
            description: "Llegaran a nuestro rancho donde se le dara una charla informativa sobre el recorrido que se va hacer. Donde visitaran una Casa tipica Dominicana y podrán disfrutar de nuestra hermosa playa Macao",
        },
        {
            title: "Regreso",
            description: "Luego de completar su estadia, lo devolveremos a la comodidad de su hotel. Totalmente renovado!",
        }
    ],
    info: {
        duration: "8 horas",
        reservation: "Reservas al tlf:"
    },
    includes: [
        "Guía turístico",
        "Comida",
        "Protección",
        "Transporte",
        "Recreación"
    ],
    cost: 40,
}

export default horse