import saona from '../../assets/images/Saona.jpg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {CloudinaryContext, Image} from "cloudinary-react";

export default () => {
    const cloud  = process.env.REACT_APP_CLOUDINARY_CLOUD
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:3500
    };

    const HeroImage = ({imageId, content,price}) => {
        return <div role={content} className={'w-1/3 flex items-center hover:z-10 group justify-center text_stroke flex-shrink hover:flex-grow hover:flex-shrink-0 hover:w-[70%] transition-all duration-300 relative'}>
            <CloudinaryContext cloudName={cloud} className={'w-full'}>
                <Image publicId={imageId} width="1300" className={'absolute object-cover top-0 w-full h-full group-hover:normal_shadow orange_shadow transition-all duration-500'}/>
            </CloudinaryContext>
            <span className={'absolute font-GelionBlack text-[100px] text-textPrimary opacity-75 group-hover:opacity-100 group-hover:text-[#FFFFFF30]'}>{content}</span>
            <div className={'right-10 bottom-10 flex items-center justify-center absolute bg-primary rounded-full p-2 aspect-square'}>
                <span className={'font-GelionBlack text-[2rem] text-textPrimary'}>
                    {price}$
                </span>
            </div>
        </div>
    }

    const HeroMobile = ({imageId, content,price}) => {
        return <CloudinaryContext cloudName={cloud} className={'w-full hero flex items-center mobile_stroke justify-center relative'}>
                <Image publicId={imageId} width="1300" className={'absolute object-cover object-top top-0 w-full h-full'}/>
                <span className={'absolute font-GelionBlack text-[90px] text-[#FFFFFF30]'}>
                    {content}
                </span>
                <div className={'right-10 bottom-10 flex items-center justify-center absolute bg-primary rounded-full p-2 aspect-square'}>
                    <span className={'font-GelionBlack text-[4rem] text-textPrimary'}>
                        {price}$
                    </span>
                </div>
        </CloudinaryContext>
    }


    return (
        <div className={'hero w-full flex '}>
            <div
                role={'services-columns'}
                className={'hidden sm:flex flex-1 bg-secundary relative overflow-hidden z-30'}
            >
                <HeroImage imageId={'chamo-tour/buggy-home'} content={'buggy'} price={29}/>
                <HeroImage imageId={'chamo-tour/saona-home'} content={'saona'} price={54}/>
                <HeroImage imageId={'chamo-tour/riding-home'} content={'riding'} price={39}/>
                <HeroImage imageId={'chamo-tour/taiguey-home'} content={'taiguey'} price={49}/>
            </div>
            <div className={'block sm:hidden w-full h-full bg-secundary z-30'}>
                <Slider {...settings} className={'w-full overflow-hidden'}>
                    <HeroMobile imageId={'chamo-tour/buggy-home'} content={'buggy'} price={29}/>
                    <HeroMobile imageId={'chamo-tour/saona-home'} content={'saona'} price={54}/>
                    <HeroMobile imageId={'chamo-tour/riding-home'} content={'riding'} price={39}/>
                    <HeroMobile imageId={'chamo-tour/taiguey-home'} content={'taiguey'} price={49}/>
                </Slider>
            </div>
        </div>
    )
}
