import {ReactComponent as Logo} from '../../assets/chamo_tour_logo.svg'
import contact from '../../utils/footer/contact.json'
import services from '../../utils/footer/services.json'
import navigate from '../../utils/footer/navigate.json'
import contactEn from '../../utils/footer/contact-en.json'
import servicesEn from '../../utils/footer/services-en.json'
import navigateEn from '../../utils/footer/navigate-en.json'
import List from './List'
import {useLocation} from "react-router-dom";

export default ({leng}) => {
    const { pathname } = useLocation()
    return (
        <>
            <div className={`flex flex-col justify-end flex-1 w-full ${pathname.includes('services') ? 'border-secundary border-t-2' : '' } mt-8 px-16 bg-primary rounded-b-[10px]`}>
                <div className={'flex justify-evenly py-5'}>
                    <List data={leng === 'es' ? contact : contactEn}/>
                    <List data={leng === 'es' ? services : servicesEn} />
                    <List data={leng === 'es' ? navigate : navigateEn} style={'hidden mobile:flex'}/>
                    <div className={'flex-1 flex justify-center items-center'}>
                        <Logo className={'w-[100px] mobile:w-48'} />
                    </div>
                </div>
            </div>
            <div
                className={
                    'flex flex-col flex-shrink w-full bg-black items-center relative h-fit'
                }
            >
                <div className={'text-textSecundary py-1 h-fit justify-between items-center bg-black bg-clip-border m-auto w-screen'}>
                    <div className={'appWidth flex justify-between px-16 gap-4 text-[8px] sm:text-[14px] md:text-[16px]'}>
                        <span className={'flex flex-col sm:block'}>
                            <span>{leng === 'es' ? 'Realizado por:' : 'Develop by:'} </span>
                            <span>Gabriel Garcia - FullStack D. <span className={'hidden mobile:inline'}> ||  Juan Garcia - {leng === 'es' ? 'Diseñador Gráfico' : 'Graphic D.'} </span></span>
                            <span className={'inline mobile:hidden'}>Juan Garcia - {leng === 'es' ? 'Diseñador Gráfico' : 'Graphic D.'}</span>
                        </span>
                        <span>{leng === 'es' ? 'Todos los derechos reservados' : 'All rights reserved'} </span>
                    </div>
                </div>
            </div>
        </>
    )
}
