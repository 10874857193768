import React, {useEffect, useState} from "react";
import {CloudinaryContext, Image} from "cloudinary-react";
import Slider from "react-slick";
import axios from "axios";

export default () => {
    const cloud  = process.env.REACT_APP_CLOUDINARY_CLOUD
    const [carouselImages,setImages] = useState([])
    const getImages = async () => {
        const response = await axios.get(`https://res.cloudinary.com/${cloud}/image/list/carousel.json`)
        setImages(response.data.resources)
    }

    useEffect(()=>{
        getImages()
    },[])

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:2500,
        responsive:[
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows:true
                }
            }
        ]
    };

    const HeroMobile = ({imageId}) => {
        return <CloudinaryContext cloudName={cloud} className={'w-[300px] h-[200px] mx-auto mobile:w-full mobile:h-[20rem] px-4 flex items-center mobile_stroke justify-center relative'}>
            <Image publicId={imageId} width="1300" className={'object-cover object-top top-0 w-full h-full'}/>
        </CloudinaryContext>
    }

    const shuffleImages = carouselImages.map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

    return <>
        {shuffleImages.length
            ? <Slider {...settings} className={'w-full py-16 z-10'}>
                {shuffleImages.map((image)=><HeroMobile imageId={image.public_id}/>)}
            </Slider>
            : null
        }
    </>
};
