import {ErrorMessage, Field, Form, Formik} from "formik";
import emailjs from '@emailjs/browser';
import {ReactComponent as Buggy} from '../../assets/images/buggy-car-svgrepo-com.svg'
import * as Yup from 'yup';
import {useState} from "react";

export default ({leng}) => {
    const [response,setResponse] = useState('')
    const FormSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Nombre muy corto')
            .max(50, 'Nombre muy largo'),
        email: Yup.string().email('Correo invalido').required('Necesitamos un correo para contactarnos con usted.'),
        message: Yup.string()
            .min(20, 'Mensaje muy corto')
            .required('Debe escribir una mensaje.'),
    });
    const { REACT_APP_EMAIL_TEMPLATE_ID: templateId, REACT_APP_EMAIL_SERVICE_ID: serviceId, REACT_APP_EMAIL_PUBLIC_KEY: publicKey} = process.env

    const sendEmail = async (data,{setStatus,resetForm}) => {
        if(!data.name) data.name = 'Anónimo'
        await emailjs.send(serviceId, templateId, data, publicKey)
            .then((result) => {
                setResponse(leng === 'es' ? 'Su mensaje fue enviada correctamente' : 'Your message was sent successfully')
                resetForm({})
            }, (error) => {
                setResponse(leng === 'es' ? 'Hubo un error al enviar su mensaje, intente en otro momento.' : 'There was an error sending your message, please try in another time.')
            });
    };

    const cleanResponse = ()=>{
        setResponse('')
    }

    return (
        <div className={'w-full'}>
            <h1 className={'text-textSecundary text-[10px] mobile:text-base'}>{leng === 'es' ? 'Contactanos' : 'Contact us'}</h1>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    message: '',
                }}
                validationSchema={FormSchema}
                onSubmit={sendEmail}
            >
                {({errors,isSubmitting}) => (
                    <>
                        {isSubmitting
                            ? <div className={'fixed top-0 left-0 w-full z-10 h-full backdrop-blur flex justify-center items-center text-[14px] mobile:text-base'}>
                                <span className={'p-8 bg-primary w-[150px] mobile:w-60 h-fit rounded-lg'}> <Buggy className={'w-20 mx-auto'}/> {leng === 'es' ? 'Su mensaje esta siendo enviado, por favor espere.' :'Your message is being sent, please wait.'}</span>
                        </div>
                            : null
                        }
                        {response
                            ? <div className={'fixed top-0 left-0 w-full z-10 h-full backdrop-blur flex justify-center items-center text-[14px] mobile:text-base'}>
                                <div className={'p-8 bg-primary w-[200px] mobile:w-60 h-fit rounded-lg text-center'}>
                                    <Buggy className={'w-20 mx-auto'}/>
                                    <h1>{response}</h1>
                                    <button type={'button'} onClick={cleanResponse} className={'px-2 py-1 bg-secundary border-none outline-none'}>Ok</button>
                                </div>
                            </div>
                            : null
                        }
                        <Form className={'flex flex-col gap-4'}>
                            <div className={'flex w-full flex-col '}>
                                <Field id="name" name="name" placeholder="name" className={'form_input'}/>
                                <ErrorMessage name="name" className={'text-secundary text-[12px] mobile:text-base'} component="span"/>
                            </div>
                            <div className={'flex w-full flex-col'}>
                                <Field
                                    id="email"
                                    name="email"
                                    placeholder="email@email.com"
                                    type="email"
                                    className={'form_input'}
                                />
                                <ErrorMessage name="email" className={'text-secundary text-[12px] mobile:text-base'} component="span"/>
                            </div>
                            <div className={'flex w-full flex-col'}>
                                <Field
                                    id="message"
                                    name="message"
                                    placeholder={leng === 'es' ? "Dejanos un mensaje y pronto te responderemos." : "Leave us a message and we will answer you soon."}
                                    as="textarea"
                                    className={'form_input h-40 resize-none'}/>
                                <ErrorMessage name="message" className={'text-secundary text-[12px] mobile:text-base'} component="span"/>
                            </div>
                            <button type="submit" disabled={!!Object.keys(errors).length || isSubmitting} className={'disabled:opacity-50 text-secundary text-[13px] mobile:text-base'}>{leng === 'es' ? 'Enviar' : 'Send'}</button>
                        </Form>
                    </>
                    )}
            </Formik>
        </div>
    )
}


