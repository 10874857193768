import map from '../../assets/images/map.png'
import bus from '../../assets/images/bus.png'
import money from '../../assets/images/money.png'
import camera from '../../assets/images/camera.png'

export default ({leng}) => {

    const Feature = ({icon, text}) => {
        return <div className={'bg-white m-0 p-2 flex items-center flex-row mobile:flex-col w-[70%] mobile:w-[40%] sm:w-[20%]'}>
            <img src={icon} alt={`feature`} className={'mr-8 mobile:m-0 h-[70px] my-3 mx-auto'} />
            <span className={'text-darkGray text-[16px]'}>{text}</span>
        </div>
    }

    return (
        <div className={'py-16 bg-buggy bg-center bg-no-repeat bg-cover w-full'}>
            <div className={'flex flex-col items-center'}>
                <h1 className={'title'}>{leng ==='es' ? 'La Aventura te espera' :'Adventure awaits'}</h1>
                <h2 className={'subtitle'}>
                    {leng ==='es' ? 'Has ya tus reservaciones' :'Make your reservations'}
                </h2>
            </div>
            <div
                className={
                    'w-full justify-center gap-4 h-fit flex flex-wrap mx-auto my-16 text-lg'
                }
            >
                    <Feature
                        icon={map}
                        text={ leng ==='es'
                            ? 'Somos una iniciativa local, que conoce las maravillas de nuestra tierra y queremos darte la mejor experiencia'
                            : 'We are a local initiative that knows the wonders of our land and we want to give you the best experience'
                    }
                    />
                    <Feature
                        icon={bus}
                        text={ leng ==='es'
                            ? 'En nuestros paquetes se incluyen transporte, los llevamos hacia la aventura y los regresamos a la comodidad de su hotel.'
                            : 'Transportation is included in our packages, we take you on an adventure and return you to the comfort of your hotel.'
                        }
                    />
                    <Feature
                        icon={money}
                        text={ leng ==='es'
                            ? 'Ten las mejores experiencias en tus vacaciones, solo o con tu familia a los mejores precios de la competencia.'
                            : 'Have the best experiences on your vacations, alone or with your family at the best competitive prices.'
                        }
                    />
                    <Feature
                        icon={camera}
                        text={ leng ==='es'
                            ? 'No te vayas sin guardar tus recuerdos, contamos con uno de los mejores fotografos de la isla.'
                            : 'Do not leave without saving your memories, we have one of the best photographers on the island.'
                        }
                    />
            </div>
        </div>
    )
}
