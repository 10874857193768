const taigueyEn = {
    _id: "taiguey-show",
    title: "TAIGUEY EMOTION SHOW",
    subtitle: "The amazing Caribbean theme party alluding to Taínos!!",
    hero:'taiguey-hero',
    stops: [
        {
            title: "Hotel",
            description: "We will pick you up at your hotel and we will go to the beautiful Bavaro beach where all our activities will take place.",
        },
        {
            title: "Experiential Museum",
            description: "Visit our Taino Experiential museum where you can learn about the culture and history of our ancestors, and participate in craft activities making clay pieces and / or molding figures of indigenous rituals",
        },
        {
            title: "Snorkeling",
            description: "We will enter on the Caribbean Sea where you will enjoy dazzling coral reefs and discover the amazing underwater world in one of the snorkeling experiences.",
        },
        {
            title: "Show Taino",
            description: "Enjoy sailing along the amazing crystalline coast of Punta Cana with our Taíno show and professional dancers who will make sure to teach you a few traditional steps of Caribbean rhythms.",
        },
        {
            title: "Natural Pool",
            description: "We will continue sailing until we reach a magnificent natural pool of white sand, where you can relax and rest in crystal clear waters and contemplate the Caribbean beauty.",
        },
        {
            title: "Return to Bavaro Beach",
            description: "With renewed energies we continue to enjoy the current Caribbean rhythms, and we will hold a dance competition, where there will be a prize for the best rhythm.",
        },
        {
            title: "Goodbye",
            description: "Upon arrival at the starting point, our bus will be waiting to return you to the comfort of your hotel along with this wonderful experience.",
        }
    ]
}

export default taigueyEn