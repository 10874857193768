import {CloudinaryContext, Image} from "cloudinary-react";

export default ({ imageId,title='servicio',subtitle,bgcolor= 'bg-secundary',titleStyle }) => {
    const cloud  = process.env.REACT_APP_CLOUDINARY_CLOUD
    return (
        <div role={'service-hero'} className={`h-fit sm:hero appWidth group flex ${bgcolor} relative overflow-hidden items-center justify-center text_stroke`}>
            <CloudinaryContext cloudName={cloud} className={'w-full'}>
                <Image publicId={imageId} className={'object-cover h-full w-full group-hover:normal_shadow orange_shadow transition-all duration-500'}/>
            </CloudinaryContext>
            <span className={`absolute text-center font-GelionRg text-white opacity-75 group-hover:opacity-100 group-hover:text-transparent transition-all duration-500`}>
                <h1 className={`leading-none text-[6.25rem] ${titleStyle ? titleStyle : ''}`}>{title}</h1>
                {subtitle
                    ? <h1 className={'text-[2.5rem] font-GelionBold leading-none'}>{subtitle}</h1>
                    : null
                }
            </span>
        </div>
    )
}
