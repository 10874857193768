import { Link } from 'react-router-dom'

export default ({ data,style='flex' }) => {
    return (
        <>
            <div className={`${style} flex-1 flex-col justify-center text-white text-[10px] mobile:text-base`}>
                <h1 className={'text-[12px] mobile:text-lg'}>{data.title}</h1>
                <div className="w-1/5">
                    <hr className="border-textSecundary w-full" />
                </div>
                {data.list.map((item) => {
                    if (item.url) return <Link to={item.url}><span className={'hover:text-textSecundary'}>{item.name}</span></Link>
                    else return <h2>{item.name}</h2>
                })}
            </div>
        </>
    )
}
