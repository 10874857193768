import ButtonMenu from './ButtonMenu'

export default ({ pathname,leng }) => {
    return (
        <div className="relative group">
            <span className="nav-buttons group-hover:text-textSecundary ">
                {leng === 'es' ? 'servicios' : 'services'}
            </span>
            <div
                className={`absolute w-[120px] pt-1 scale-y-0 flex flex-col w-[120px] left-0 group-hover:scale-y-100 z-20 origin-top-left transition-all`}
            >
                <div className={'hover:translate-x-[2%] transition'}>
                    <ButtonMenu
                        selected={pathname.match(/buggy-tour/i)}
                        text={'Buggy Tour'}
                        uri={'/services/buggy-tour'}
                    />{' '}
                </div>

                <div className={'hover:translate-x-[2%] transition'}>
                    <ButtonMenu
                        selected={pathname.match(/saona-tour/i)}
                        text={'Saona Tour'}
                        uri={'/services/saona-tour'}
                    />
                </div>

                <div className={'hover:translate-x-[2%] transition'}>
                    <ButtonMenu
                        selected={pathname.match(/taiguey-show/i)}
                        text={ 'Taiguey Show'}
                        uri={'/services/taiguey-show'}
                    />{' '}
                </div>

                <div className={'hover:translate-x-[2%] transition'}>
                    <ButtonMenu
                        selected={pathname.match(/riding-tour/i)}
                        text={ leng === 'es' ? 'Paseo a Caballo' : 'Horse Riding'}
                        uri={'/services/riding-tour'}
                    />{' '}
                </div>


            </div>
        </div>
    )
}
