import Hero from "../components/hero/Hero";
import saonaIsland from "../utils/services/saona-island";
import ContactForm from "../components/contact/ContactForm";
import Footer from "../components/footer/Footer";
import socials from "../utils/contact/socials.json"
import {Link} from "react-router-dom";

export default ({leng}) => {
    return (
        <>
        <Hero imageId={'chamo-tour/contact-hero'} bgcolor={'bg-primary'} title={leng === 'es' ? 'CONTACTO' : 'CONTACT'} titleStyle={'text-[150px] font-GelionBlack text-secundary group-hover:text-transparent'}/>
        <div className={'max-w-[950px] w-full flex flex-wrap my-8 justify-center'}>
            <div className={'w-full mobile:w-1/4 px-8'}>
                <div className={'flex-1 flex flex-row mobile:flex-col gap-x-8 justify-center text-white'}>
                    <span className={'text-textSecundary text-[10px] mobile:text-base'}>{socials.title}</span>
                    {socials.list.map((item) => {
                        if (item.url) return <a href={item.url} target={"_blank"}><span className={'text-[12px] mobile:text-[1.15rem] hover:text-textSecundary'}>{item.name}</span></a>
                        else return <h2>{item.name}</h2>
                    })}
                </div>
            </div>
            <div className={'w-full mobile:w-3/4 mobile:border-l-[2px] mobile:border-white px-16'}>
                <ContactForm leng={leng}/>
            </div>
        </div>
        <Footer leng={leng}/>
        </>
    )
}
