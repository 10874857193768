import { Link } from 'react-router-dom'
import Contact from '../../../assets/svg/Contact.jsx'
import horse from '../../../assets/images/horse-svgrepo-com.svg'
import saona from '../../../assets/images/beach-svgrepo-com.svg'
import buggy from '../../../assets/images/buggy-car-svgrepo-com.svg'
import camera from '../../../assets/images/camera.png'

export default ({ show, setShow,leng,setLeng }) => {
    const handleClick = () => {
        setShow(!show)
    }

    return (
        <div className={`${show ? '' : 'h-0'} z-40 fixed mobile:hidden w-full right-0 px-8 text-[16px]`}>
            <div
                id="Sidebar"
                className={`${show ? '' : 'scale-y-0'} h-fit bg-white h-fit transform origin-top-left ease-in-out transition duration-500 flex justify-start items-start flex-col`}
            >
                <div className="mt-6 flex flex-col justify-start items-start  px-4 w-full space-y-3 pb-5 ">
                    <Link
                        to={'/services/buggy-tour'}
                        onClick={handleClick}
                        className="flex justify-start items-center space-x-6 active:text-white active:bg-primary text-darkGray py-3 pl-4  w-full "
                    >
                        <img src={buggy} alt={'buggy'} className={'h-[30px]'} />
                        <p className="leading-4">
                            {leng === 'es' ? 'Aventura en Buggy' : 'Buggy Adventure'}
                        </p>
                    </Link>
                    <Link
                        to={'/services/saona-tour'}
                        onClick={handleClick}
                        className="flex justify-start items-center space-x-6 active:text-white active:bg-primary text-darkGray py-3 pl-4  w-full "
                    >
                        <img src={saona} alt={'Saona'} className={'h-[30px]'} />
                        <p className="leading-4">{leng === 'es' ? 'Isla Saona' : 'Saona Island'} </p>
                    </Link>
                    <Link
                        to={'/services/taiguey-show'}
                        onClick={handleClick}
                        className="flex justify-start items-center space-x-6 active:text-white active:bg-primary text-darkGray py-3 pl-4  w-full "
                    >
                        <img src={saona} alt={'Taiguey'} className={'h-[30px]'} />
                        <p className="leading-4">Taiguey Show</p>
                    </Link>
                    <Link
                        to={'/services/riding-tour'}
                        onClick={handleClick}
                        className="flex justify-start items-center space-x-6 active:text-white active:bg-primary text-darkGray py-3 pl-4  w-full "
                    >
                        <img src={horse} alt={'horse'} className={'h-[30px]'} />
                        <p className="leading-4">
                            {leng === 'es' ? 'Paseo a Caballo' : 'Horse Riding'}
                        </p>
                    </Link>
                </div>
                <div className="w-full px-4">
                    <hr className=" border-gray-100 w-full" />
                </div>
                <div className="mt-6 flex flex-col justify-start items-start px-4 w-full space-y-3 pb-5 ">
                    <Link
                        to={'/contact'}
                        onClick={handleClick}
                        className="focus:outline-none flex jusitfy-start  active:text-white active:bg-primary text-darkGray py-3 pl-4 items-center w-full space-x-6"
                    >
                        <Contact className={'pr-4'}/>
                        <p className="leading-4">{leng === 'es' ? 'Contacto' : 'Contact'}</p>
                    </Link>
                    <Link
                        to={'/photos'}
                        onClick={handleClick}
                        className="focus:outline-none flex jusitfy-start m-0 active:text-white active:bg-primary text-darkGray py-3 pl-4 items-center w-full space-x-6"
                    >
                        <img src={camera} alt={'Camara'} className={'w-[28px] p-[1px] m-0 h-[28px]'}/>
                        <p className="leading-4">{leng === 'es' ? 'Fotos' : 'Photos'}</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}
