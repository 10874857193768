const buggyEn = {
    _id: "buggy-tour",
    title: "ADVENTURE BUGGIES",
    subtitle: "The extreme side of the island",
    hero:'buggy-hero',
    stops: [
        {
            title: "Hotel",
            description: "From 7:30 a.m. we pick you up at your hotel to our ranch, you will enjoy a relaxing landscape while you dream of everything you will do that day.",
        },
        {
            title: "Macao Ranch",
            description: "Upon arrival at our ranch, our tour guide will explain the activities, place you behind the wheel, start the engine and head to Macao beach.",
        },
        {
            title: "Macao beach",
            description: "Preferred by all, Macao beach stands out for being the cleanest and with the whitest sand in the region. After a dip, we'll drive to the next stop.",
        },
        {
            title: "Cultural House",
            description: "We will arrive at the typical house of the Dominican Republic, they will know our coffee, cocoa, and tobacco plants and they will have the opportunity to see and try the artisan work.",
        },
        {
            title: "Taina Cave",
            description: "Finally the best stop of this adventure. You will enjoy fresh and crystal clear waters to rejuvenate the soul in this wonderful cenote.",
        },
        {
            title: "Macao Ranch",
            description: "Back at the ranch, you have the option to review the memories taken by our photographic team, drink our national drink MamaJuana! and take a shower.",
        },
        {
            title: "Return",
            description: "We will return you to the comfort of your hotel between 6pm in the afternoon. After this wonderful experience you deserve to rest.",
        }
    ]
}

export default buggyEn
