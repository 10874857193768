import { Link } from 'react-router-dom'

export default ({ selected, uri, text }) => {
    return (
        <>
            <Link
                to={uri}
                className={`${
                    selected ? 'text-textSecundary' : null
                } nav-buttons hover:text-textSecundary w-max`}
            >
                {text}
            </Link>
        </>
    )
}
