import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {ReactComponent as Logo} from '../../assets/chamo_tour_logo.svg'
import ButtonMenu from './ButtonMenu'
import ServiceMenu from './ServiceMenu.jsx'
import Sidebar from './sidebar/Sidebar.jsx'
import Toogler from './Toogler.jsx'
import { Outlet } from 'react-router-dom'
import usFlag from '../../assets/images/us-flag.png'
import esFlag from '../../assets/images/es-flag.png'

export default ({leng,setLeng}) => {
    const { pathname } = useLocation()
    const [show, setShow] = useState(false)

    return (<>
        <div className="w-full">
                <nav className={` px-16 w-full top-0 left-0 flex justify-end gap-8 mobile:justify-between h-[60px] ${pathname.match(/photos/i) ? 'bg-transparent fixed mobile:absolute mobile:top-0' : 'bg-primary fixed'} z-40 mobile:h-20 items-center font-GelionBlack text-textPrimary`}>
                    {/* Logotipo */}

                    <div className="flex flex-1 justify-end mobile:justify-start ml-24 h-fit">
                        <Link to={'/'}>
                            <Logo className={'h-[30px] mobile:h-10 hover:contrast-0'}/>
                        </Link>
                    </div>
                    {/* Menu for sm > 900px */}
                    <div className="hidden mobile:flex flex-1 gap-x-6 items-center mr-8 justify-end">
                        <ServiceMenu pathname={pathname} leng={leng} />
                        <ButtonMenu
                            selected={pathname.match(/photos/i)}
                            text={ leng==='es' ? 'fotos' : 'photos'}
                            uri={'/photos'}
                        />
                        <ButtonMenu
                            selected={pathname.match(/contact/i)}
                            text={ leng==='es' ? 'contacto' : 'contact'}
                            uri={'/contact'}
                        />
                        <div className={'flex flex-nowrap relative'}>
                            {leng === 'es'
                                ? <img src={esFlag} alt={'Español'} className={'w-6 h-6 absolute -z-10 left-0'}/>
                                : <img src={usFlag} alt={'English'} className={'w-6 h-6 absolute -z-10 left-0'}/>
                            }
                            <select
                                defaultValue={'es'}
                                onChange={(e)=>setLeng(e.target.value)}
                                className={'p-0 pl-6 m-0 bg-transparent w-[80px] outline-none border-0 focus:outline-0 focus:boder-0'}>
                                <option value={'es'} selected={leng==='es'} className={'bg-primary m-0'}>Esp</option>
                                <option value={'en'} selected={leng==='en'} className={'bg-primary m-0'}>Eng</option>
                            </select>
                        </div>

                    </div>

                    {/* Menu for media < 640px */}

                    <Toogler setShow={setShow} show={show} leng={leng} setLeng={setLeng} />
                </nav>
            <span className={`h-[60px] mobile:h-20 ${pathname.match(/photos/i) ? 'block mobile:hidden' : 'block'}`}/>
                <Sidebar setShow={setShow} show={show} leng={leng} setLeng={setLeng} />
        </div>
        <Outlet></Outlet>
        </>
    )
}
