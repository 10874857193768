const saonaEn = {
    _id: "saona-tour",
    title: "SAONA ISLAND",
    subtitle: "one of the most beautiful beaches on the island",
    hero:'saona-hero',
    stops: [
        {
            title: "Hotel",
            description: "We will pick you up at your hotel and our tour guide will accompany you and inform you of the day's activities. On the way to the Bayahibe region, start your adventure.",
        },
        {
            title: "Bayahibe Port",
            description: "From the port of Bayahibe and lifeguards in hand, we will take a speedboat to one of the most beautiful islands in the Caribbean, Saona! Not without first making a small stop...",
        },
        {
            title: "Palmilla",
            description: "Welcome to the natural pool of Palmilla, with an incredible view, marine fauna and crystal clear waters you can enjoy a relaxing landscape. A perfect begin before reaching our destination.",
        },
        {
            title: "Catuano Channel",
            description: "At the end of our first stop, we resume our trip to Saona Island, passing in front of the Catuano channel.",
        },
        {
            title: "Saona Island",
            description: "Finally we arrive at the best area of the beach in the whole island, we will offer you an incredible menu and the enjoyment of our services.",
        },
        {
            title: "Return to Bayahibe",
            description: "It's time to go back, but our journey is not over yet. We will enjoy an open bar and a recreation space inside our catamaran on the return",
        },
        {
            title: "Goodbye",
            description: "Upon arrival in Bayahibe, our bus will be waiting to return you to the comfort of your hotel along with this wonderful experience.",
        }
    ]
}

export default saonaEn